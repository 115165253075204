import React, { useEffect, useState } from "react";
import axiosHelper from "../../../Utilities/axiosHelper";
import { connect } from "react-redux";
import { getUserPlatforms, getBrokerInfo, newPlatform } from "../../../api/profile"; // Adjust the import based on your file structure
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import { API_ENDPOINTS } from "apiConfig";
import swal from "sweetalert";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PlatformInfoFormComponent from "./PlatformInfoFormComponent";
import { useMaterialUIController } from "context";
import { getTradeLink } from "api/trade";
import linkActive from "assets/images/link_active.png";
import linkDisable from "assets/images/link_disable.png";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/material/Tooltip';

let prePlatforms;
const ListPlatformsComponent = ({
  getUserPlatforms,
  getBrokerInfo,
  userPlatforms,
  setSuccessSB,
  setErrorSB,
  setContent,
  brokerInfo,
  newCard,
  newPlatform,
  profile,
  user,
  tradeLink,
  getTradeLink
}) => {
  const [editPlatformId, setEditPlatformId] = useState(null);
  const handleEdit = (platform) => {
    setEditPlatformId(platform.id);
  };

  const [userPlatforms1, setUserPlatforms1] = useState(userPlatforms);

  const closeEditForm = () => {
    setEditPlatformId(null);
  };
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  const showAlert = (id, deleteActive) => {
    if (!deleteActive) {
      setContent("Account can't be deleted until the link it is attached to is deleted !");
      setErrorSB(true);
      return;
    }
    swal({
      title: "Are you sure?",
      text: "You will no longer be connected to this platform!",
      icon: "warning",
      buttons: { cancel: "No", confirm: "OK" },
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if(handleDelete(id)){
          swal("Your profile for this platform has been deleted!", {
            icon: "success",
          });
        }
      } else {
        swal("Your profile is still connected!", {
          icon: "error",
        });
      }
    });
  };

  const handleDelete = async (id) => {
    try {
      let response = await axiosHelper.delete(
        `${API_ENDPOINTS.profileEndpoints.deletePlatformDataByID}${id}`
      );
      if (response.status === 200) {
        getUserPlatforms(); 
        setContent('Platform deleted');
        setSuccessSB(true);
        return true;
      }else{
        setContent(response.message);
        setErrorSB(true);
        return false;
      }
    } catch (error) {
    }
  };

  useEffect(()=> {
    const sortUserPlatformsByDate = () => {
      const sortedPlatforms = [...userPlatforms].sort((a, b) =>
        new Date(b.createdDate) - new Date(a.createdDate)
      );
      setUserPlatforms1(sortedPlatforms);
    };
    sortUserPlatformsByDate();
  }, [userPlatforms]);

  useEffect(() => {
    getBrokerInfo();
    getUserPlatforms();
    getTradeLink();
  }, [getUserPlatforms, getBrokerInfo, getTradeLink]);

  if (!userPlatforms) {
    return <Typography>Click Add Platforms to create platform account information</Typography>;
  }

  const addNewCard = () => {
    newPlatform();
  };
  
  prePlatforms = userPlatforms;
  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={3} alignItems="center" style={{ marginLeft: 1, marginTop: 5 }}>
            <Grid item>
              <Typography variant='h5' gutterBottom>
                Connected Platforms
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='primary'
                style={{ color: "white", fontSize: "14px" , height:"14px"}}
                onClick={addNewCard}
                disabled={!user?.isSubscribed}
                id="addPlatform"
              >
                {!profile.new ?
                "Add Platform" : 
                "Cancel"}
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {newCard && 
              <Grid item xs={12} sm={6} md={4} key={'add'}>
                <PlatformInfoFormComponent
                  setSuccessSB={setSuccessSB}
                  setErrorSB={setErrorSB}
                  setContent={setContent}
                />
              </Grid>
            }
            {userPlatforms1.map((platform) => {
              const uniqueInfo = JSON.parse(
                platform.platformUniqueInfo || "{}"
              );
              const isEditing = editPlatformId === platform.id;
              const platformData = brokerInfo.find(p => p.value === platform.platformId);
              const platformName = platformData ? platformData.label : 'Unknown';
              let deleteActive = true;
              let link_active = false;
              for (let i = 0; i < tradeLink.length; i++) {
                if (tradeLink[i]?.traderAccountInfo?.id === platform.id || tradeLink[i]?.copyCatAccountInfo?.id === platform.id) {
                  deleteActive = false;
                  link_active = tradeLink[i].isActive;
                }               
              }
              return (
                <Grid item xs={12} sm={6} md={4} key={platform.id}>
                  <Card elevation={3} style={{ margin: "10px" }}>
                    <CardContent>
                      {!isEditing && (
                        <>
                        <div className="flex justify-between">
                          <Typography variant='h5' gutterBottom>
                            {platform.accountName}
                          </Typography>
                          {!deleteActive && <Tooltip title={link_active ? "Sending/Receiving" : "Linked but inactive"}> 
                            <img src={link_active ? linkActive : linkDisable} alt='Share' className='w-6 h-6' />
                          </Tooltip> }
                          {
                            platform.accountName === 'Subscribers' && 
                            <Tooltip title="Linking your public account to this account in the trade link page will send your trades to all of your subscribers.">
                              <QuestionMarkIcon sx={{ color: darkMode? "#ffffff": null, width: 30, height: 30 }}/>
                            </Tooltip>
                          }
                        </div>
                          <Typography variant='h5' gutterBottom>
                          {platformName}
                          </Typography>
                          <Typography variant='h6' style={{ fontSize: 'large' }}>
                            Account Name: {platform.accountName}
                          </Typography>
                          <Typography variant='h6' style={{ fontSize: 'large' }}>
                            Account ID: {platform.accountId}
                          </Typography>
                          {uniqueInfo.AccountNumber && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Account Number: {uniqueInfo.AccountNumber}
                            </Typography>
                          )}
                          {uniqueInfo.Email && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Email: {uniqueInfo.Email}
                            </Typography>
                          )}
                          {uniqueInfo.Demo && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Demo: {uniqueInfo.Demo}
                            </Typography>
                          )}
                          {uniqueInfo.Server && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Server: {uniqueInfo.Server}
                            </Typography>
                          )}
                          {uniqueInfo.MatchTraderUrl && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Match Trader Url: {uniqueInfo.MatchTraderUrl}
                            </Typography>
                          )}
                          {uniqueInfo.TelegramUserName && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Telegram UserName: {uniqueInfo.TelegramUserName}
                            </Typography>
                          )}
                          {platform.isPublic && (
                            <Typography variant='h6' style={{ fontSize: 'large' }}>
                              Public Account
                            </Typography>
                          )}
                          {platform.accountName !== 'Subscribers' && 
                            <div style={{ marginTop: "10px" }}>
                              {
                                !platform.isPublic && 
                                <Button
                                  variant='contained'
                                  color='primary'
                                  onClick={() => handleEdit(platform)}
                                  size='small'
                                  id={"edit" + platform.accountName}
                                  disabled={!user?.isSubscribed}
                                  style={{ marginRight: "10px", color: "white" }}
                                >
                                  Edit
                                </Button>
                              }
                              <Button
                                variant='contained'
                                style={{
                                  backgroundColor: "#d32f2f",
                                  color: "white",
                                }}
                                onClick={() => showAlert(platform.id, deleteActive)}
                                size='small'
                                  id={"delete" + platform.accountName}
                              >
                                Delete
                              </Button>
                            </div>
                          }
                        </>
                      )}

                      {isEditing && (
                        <EditFormComponent1
                          darkMode={darkMode}
                          platform={platform}
                          onClose={closeEditForm}
                          setSuccessSB={setSuccessSB}
                          setErrorSB={setErrorSB}
                          setContent={setContent}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}

function EditFormComponent({
  getUserPlatforms,
  platform,
  onClose,
  setSuccessSB,
  setErrorSB,
  setContent,
  darkMode
}) {
  const [formData, setFormData] = useState(
    JSON.parse(platform.platformUniqueInfo || "{}")
  );
  const [accountName, setAccountName] = useState(platform.accountName);
  const [accountId, setAccountId] = useState(platform.accountId);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isDemo, setIsDemo] = useState(platform.platformUniqueInfo.Demo);
  const [editActive, setEditActive] = useState(false);
  const [error, setError] = useState(null);

  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);
  
  useEffect(()=> {
    setIsDemo(formData.Demo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData])

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleChange = (key, value) => {
    validData(key, value);
    setFormData((prev) => ({ ...prev, [key]: value }));
  };


  const handleNameChange = (value) => {
    setAccountName(value);
    validData('accountName', value);
  };

  const handleIDChange = (value) => {
    setAccountId(value);
    validData('accountId', value);
  };

  const handleIsDemoChange = (value) => {
    setIsDemo(value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const closeForm = () => {
    onClose();
  };

  const validData = (key1, value1) => {
    let json = formData;
    json.accountName = accountName;
    json.accountId = accountId;
    json[key1] = value1;
    setEditActive(checkForObjectEmptyStrings(json));
  }

  const isEmptyString = (value) => {
    return value === "";
  }
  
  const checkForObjectEmptyStrings = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && isEmptyString(obj[key])) {
        return true;
      }
    }
    return false;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let dynamicData = {};
    Object.keys(formData).forEach(key => {
      if (formData[key]) {
        dynamicData[key] = formData[key];
      }
    });

    if (password) {
      dynamicData.Password = password;
    } else if (formData.Password) {
      dynamicData.Password = formData.Password;
    }
    dynamicData.Demo = isDemo;

    let filteredPlatforms = prePlatforms.filter(p => p.id !== platform.id);
    let isDuplicateName = filteredPlatforms.some(platform => platform.accountName === accountName);
    if (isDuplicateName) {
      setContent("Account Name must be unique!");
      setErrorSB(true);
      return;
    }
    if (accountId === dynamicData.AccountNumber) {
      setContent("Account ID and Account Number must be different !");
      setErrorSB(true);
      return;
    }

    const updatedData = {
      platformId: platform.platformId,
      accountId: accountId,
      accountName: accountName,
      platformAccountInfoId: platform.id,
      isDemo: isDemo,
      isPublic: platform.isPublic,
      platformAccountInfoJson: JSON.stringify(dynamicData),
    };

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const response = await axiosHelper.put(
        API_ENDPOINTS.profileEndpoints.platformAccountInfo,
        updatedData,
        config
      );
      if (response.status === 200) {
        setContent("Edit successful");
        setSuccessSB(true);
        getUserPlatforms();
        if (onClose) {
          onClose();
        }
      }else{
        setContent(response.message);
        setErrorSB(true);
      }
    } catch (error) {
      setContent("Edit unsuccessful");
      setErrorSB(true);
    }
  };

  return (
    <div>
      <Typography variant='h6' style={{ fontSize: 'large' }}>
        Edit {formData.selectedPlatform} Platform
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label='Account ID'
          value={accountId}
          id={accountId}
          onChange={(e) => handleIDChange(e.target.value)}
          fullWidth
          margin='normal'
          required
        />
        <TextField
          label='Account Name'
          value={accountName}
          onChange={(e) => handleNameChange(e.target.value)}
          fullWidth
          margin='normal'
          required
        />
        {Object.entries(formData).map(([key, value]) => {
          if (
            key === "SelectedPlatform" ||
            key === "selectedPlatform" ||
            key === "Password" ||
            key === "accountName" ||
            key === "accountId" ||
            key === "public"
          ) {
            return null;
          }
          else if( key === "Demo")
          {
            return (
            <Grid container className="items-center">
            <FormLabel item component="legend" style={{ fontSize: 15 }}>Demo</FormLabel>
              <RadioGroup row item className="ml-10">
                  <FormControlLabel
                    key="true"
                    value="true"
                    control={
                      <Radio
                        value="true"
                        checked={isDemo === "true"}
                        onChange={(e) => handleIsDemoChange(e.target.value)}
                        aria-required= {"true"}
                      />
                    }
                    label="true"
                    style={{ display: 'inline-flex', marginRight: '16px' }}
                  />
                  <FormControlLabel
                    key="false"
                    value="false"
                    control={
                      <Radio
                        value="false"
                        checked={isDemo ===  "false"}
                        onChange={(e) => handleIsDemoChange(e.target.value)}
                        aria-required= {"true"}
                      />
                    }
                    label="false"
                    style={{ display: 'inline-flex', marginRight: '16px' }}
                  />
              </RadioGroup>
          </Grid>
            )
          }
          if (key === "matchTraderUrl") {
            return(
                <div>
                  <p className="text-[11px] ml-[80px]" style={{ color: textColor }}>Get this from your broker</p>
                  <TextField
                    key={key}
                    label={key}
                    value={value}
                    onChange={(e) => handleChange(key, e.target.value)}
                    fullWidth
                    margin='normal'
                    style={{ marginTop: 0 }}
                    required
                  />
                </div>
              );
          }else{
            return (
              key === "MatchTraderUrl" ?
              <TextField
                type="text"
                key={key}
                label={key}
                value={value}
                onChange={(e) => handleChange(key, e.target.value)}
                fullWidth
                margin='normal'
                error={!!error}
                helperText={error || <p style={{color: darkMode? "white" : "black"}}>Enter a valid .com domain</p>}
                required
              />
              :
              <TextField
                type="text"
                key={key}
                label={key}
                value={value}
                onChange={(e) => handleChange(key, e.target.value)}
                fullWidth
                margin='normal'
                required
              />
            );
          }
        })}
        {formData.hasOwnProperty("Password") && (
          <TextField
            label='Password'
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            placeholder="Password not shown.Enter new password to change it"
            margin='normal'
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    sx={{ color: textColor }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
         )}
        <Button
          onClick={closeForm}
          variant='contained'
          color='secondary'
          sx={{ marginRight: 3 }}
          style={{ color: "white" }}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          disabled={editActive}
          id={"submit" + accountName}
          style={{ color: "white" }}
        >
          Submit
        </Button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userPlatforms: state.profile?.userPlatforms,
  brokerInfo: state.profile?.brokerInfo,
  newCard: state.profile?.new,
  profile: state.profile,
  user: state.auth?.user,
  tradeLink: state.tradeLink?.tradeLink || [],
});

export const EditFormComponent1 = connect(mapStateToProps, { getUserPlatforms, getBrokerInfo })(EditFormComponent);

export default connect(mapStateToProps, { getUserPlatforms, getBrokerInfo, newPlatform, getTradeLink })(
  ListPlatformsComponent
);