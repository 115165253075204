import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext'; 
import LoginScreen from './Pages/authentication/sign-in';
import RegisterScreen from './Pages/authentication/sign-up';
import ResetPasswordScreen from './Pages/authentication/reset-password/cover';
import Dashboard from './Pages/dashboard';
import Admin from './Pages/admin';
import ProtectedRoute from './components/ProtectedRoute'; 
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import { MaterialUIControllerProvider, useMaterialUIController, setMiniSidenav } from "context";
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct.png";
import TradeCopy from './Pages/trade';
import Account from './Pages/profile';
import InfoPage from './Pages/infoPage';
import DownloadTutorial from './Pages/downloadTutorial';
import Billing from './Pages/billing';
import { Provider } from "react-redux";
import store from "./store";
import TokenValidation from './Pages/tokenValidation';
import ErrorPage from "./Pages/error";
import { connect } from "react-redux";
import { setDarkMode } from "context";
import GettingStarted from "./Pages/gettingStarted";
import CustomModal from "components/CustomModal";
import { resetState } from "api/auth";
import axiosHelper from "./Utilities/axiosHelper";
import { API_ENDPOINTS } from "./apiConfig";

function AppWrapper() {
  return (
    <AuthProvider>
		<Router>
			<MaterialUIControllerProvider>
				<Provider store={store}>
					<App1 />
				</Provider>
			</MaterialUIControllerProvider>	
		</Router>
    </AuthProvider>
  );
}

function App({ darkMode1, resetState }) {

	const location = useLocation();
	
	useEffect(() => {
    const handleAffiliateClick = async () => {
        const params = new URLSearchParams(location.search);
        const ref = params.get("ref");
        if (ref) {
            localStorage.setItem("affiliateRef", ref);
            try {
							await axiosHelper.post(`${API_ENDPOINTS.affiliate.click}?referralCode=${encodeURIComponent(ref)}`);
            } catch (error) {
                console.error("Error occurred while sending affiliate click data:", error);
            }
        }
    };

    handleAffiliateClick();
}, [location]);

	
	const [controller, dispatch] = useMaterialUIController();
	const [storedValue, setStoredValue] = useState(localStorage.getItem('isExpired'));
	const {
		miniSidenav,
		sidenavColor,
		transparentSidenav,
		whiteSidenav,
		darkMode,
	} = controller;

	useEffect(()=>{
		setDarkMode(dispatch, localStorage.getItem("darkMode") === "true");
		const handleCustomEvent = (event) => {
		  if (event.detail.key === 'isExpired') {
			setStoredValue(event.detail.value);
		  }
		};



	
		window.addEventListener('localStorageChanged', handleCustomEvent);
	
		return () => {
		  window.removeEventListener('localStorageChanged', handleCustomEvent);
		};
	},[darkMode1, dispatch]);

	const [onMouseEnter, setOnMouseEnter] = useState(false);
	
	const handleOnMouseEnter = () => {
		if (miniSidenav && !onMouseEnter) {
			setMiniSidenav(dispatch, false);
			setOnMouseEnter(true);
		}
	};

	const handleOnMouseLeave = () => {
		if (onMouseEnter) {
			setMiniSidenav(dispatch, true);
			setOnMouseEnter(false);
		}
	};

	const onHidden = () => {
		if( !miniSidenav && window.innerWidth < 1200 ){
			setMiniSidenav(dispatch, true);
		}
	}

	return (
		<ThemeProvider theme={darkMode ? themeDark : theme}>
			<CustomModal open={storedValue} setOpen={setStoredValue} resetState={resetState} title="Error" content="Your session has expired. Please log back in." />
			<CssBaseline />
			{localStorage.getItem('tokenExpiration') != null && (
				<>
					<Sidenav
						color={sidenavColor}
						brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
						brandName="Copy Cat"
						routes={routes}
						onMouseEnter={handleOnMouseEnter}
						onMouseLeave={handleOnMouseLeave}
					/>
					<Configurator />
				</>
			)}
			<div onClick={onHidden}>
				<Routes>
					<Route path="/" element={<Navigate to={"/dashboard"} />} />
					<Route
						path="/trade-link"
						element={
							<ProtectedRoute>
								<TradeCopy />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/accounts"
						element={
							<ProtectedRoute>
								<Account />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/billing"
						element={
							<ProtectedRoute>
								<Billing />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/dashboard"
						element={
							<ProtectedRoute>
								<Dashboard />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin"
						element={
							<ProtectedRoute>
								<Admin />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/info"
						element={
							<ProtectedRoute>
								<InfoPage />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/getting-started"
						element={
							<ProtectedRoute>
								<GettingStarted />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/tutorials-downloads"
						element={
							<ProtectedRoute>
								<DownloadTutorial />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/login"
						element={
							<ProtectedRoute>
								<LoginScreen />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/signup"
						element={
							<ProtectedRoute>
								<RegisterScreen />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/logout"
						element={
							<ProtectedRoute>
								<LoginScreen />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/validate-token"
						element={
							<TokenValidation />
						}
					/>
					<Route
						path="/reset-password"
						element={
							<ResetPasswordScreen />
						}
					/>
					<Route
						path="/error"
						element={
							<ErrorPage />
						}
					/>
				</Routes>
			</div>
		</ThemeProvider>
	);
}

const mapStateToProps = ((state)=>({
	darkMode1: state.auth?.user?.settings?.useDarkMode
}))

export const App1 = connect(mapStateToProps, { resetState })(App)

export default AppWrapper;