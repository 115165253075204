import React from "react";
import { Modal } from "antd";
import { useMaterialUIController } from "context";
import { CloseOutlined } from '@ant-design/icons';
import { Typography } from "antd";
import MDBox from "components/MDBox/index.js";
import MDButton from "components/MDButton/index.js";
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;


const CustomModal = ({ open, setOpen, resetState, title, content, onClose }) => {
  const navigate = useNavigate();
  

  const handleClose = () => {
    setOpen(false);
    resetState();
    if (onClose) onClose();
  }; 

  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let themeStyle = null;
  let selectStyle = null;
  if (darkMode) {
    themeStyle = {
      content: { backgroundColor: 'black' },
      header: { 
        backgroundColor: 'black',
        color: '#ffffff'
      }, 
      footer: { backgroundColor: 'black' },
    };

    selectStyle = {
      color: '#ffffff'
    };
  }

  const handleOk = () => {
    localStorage.clear();
    if (title === 'Error') {
      resetState();
    }
    navigate('/login');
    handleClose();
  }

  


  return (
    <Modal   
      title={<h3 style={{ fontWeight: 'bold', color: darkMode? 'white' : 'black' }}>{title}</h3>}
      centered
      open={open}
      onOk={handleClose}
      styles={themeStyle}
      onCancel={handleClose}
      closeIcon={<CloseOutlined style={selectStyle} id="closeToken" />}
      okButtonProps={{ disabled: false }}
      cancelButtonProps={{
        disabled: false,
      }}
      footer={[
        <div>
          <MDBox mt={4} mb={1}>
            <MDButton
              variant='gradient'
              color='info'
              fullWidth
              id="handleToken"
              onClick={handleOk}
            >
              Ok
            </MDButton>
          </MDBox>
        </div>
      ]}
    >
    <Text style={{ color: darkMode? 'white' : 'black' }} id="tokenText">{content}</Text>
    </Modal>
  );
};

export default CustomModal;
