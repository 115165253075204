import * as React from "react";
import { useState, useEffect } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Header from "./components/Header";
import { getServerInfo } from "api/infoPage";
import { connect } from "react-redux";
import headerBackgroundImage  from "assets/images/info.png";
import { loadUser } from "api/auth";
import tradeLocker from "assets/images/tradeLocker.jpg";
import matchTrader from "assets/images/matchTrader.jpg";
import tradingView from "assets/images/tradingView.jpg";
import metaTrader4 from "assets/images/metaTrader4.jpg";
import metaTrader5 from "assets/images/Meta-Trader5.jpg";
import telegram from "assets/images/Telegram.png";
import { useMaterialUIController } from "context";

function InfoPage({ getServerInfo, serverInfo, loadUser, user }) {

  const [feVersion, setFeVersion] = useState('');

  React.useEffect(() => {
    getServerInfo();
    if (user === null) {
      loadUser();
    }
  },[getServerInfo, loadUser, user]);

	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  useEffect(() => {
    const getVersionFromCache = async () => {
      const cacheNames = await caches.keys();
      for (const cacheName of cacheNames) {
        const cache = await caches.open(cacheName);
        const response = await cache.match('version');
        if (response) {
          const version = await response.text();
          setFeVersion(version);
          break;
        }
      }
    };
    
    getVersionFromCache();
    getServerInfo();
  }, [getServerInfo]);

  
  const textColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return 'black';
    }
  }, [darkMode]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header backgroundImage={headerBackgroundImage}>
        <MDBox mt={5} mb={3}>
          <div className="px-4 space-y-5">
            <div className="grid lg:grid-cols-8 md:grid-cols-5 grid-cols-1 md:gap-0 gap-2">
              <p style={{ color: textColor }} className="text-lg my-5 md:col-span-2 col-span-1 content-center md:text-left text-center">Supported Trade Signal Platforms</p>
              <a href="https://www.metatrader4.com/en/download" className="flex justify-center">
              <img src={metaTrader4} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>
            <a href="https://www.tradingview.com/" className="flex justify-center">
              <img src={tradingView} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>
            <a href="https://www.metatrader5.com/en/download" className="flex justify-center">
              <img src={metaTrader5} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>
            <a href="https://telegram.org" className="flex justify-center">
              <img src={telegram} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>

            </div>
            <div className="grid lg:grid-cols-8 md:grid-cols-5 grid-cols-1 md:gap-0 gap-2">
              <p style={{ color: textColor }}  className="text-lg my-5 md:col-span-2 col-span-1 content-center md:text-left text-center">Supported Copy Cat Platforms</p>
              <a href="https://www.metatrader4.com/en/download" className="flex justify-center">
              <img src={metaTrader4} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>
            <a href="https://tradelocker.com/start-trading/" className="flex justify-center">
              <img src={tradeLocker} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>
            <a href="https://mt.match-trade.com/login" className="flex justify-center">
              <img src={matchTrader} alt="" className="w-[120px] rounded-md hover:scale-110 transition-transform duration-200" />
            </a>

            </div>
            <p style={{ color: textColor }}  className="text-lg my-5 md:col-span-2 col-span-1 content-center md:text-left text-center">Webhook URL: &nbsp;&nbsp;&nbsp;{`https://app.copiercat.com/api/Trade/copy?token={yourToken}`}</p>
            <p style={{ color: textColor }}  className="text-lg my-5 md:col-span-2 col-span-1 content-center md:text-left text-center">Support Email: &nbsp;&nbsp;&nbsp;support@copiercat.com</p>
          </div>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth?.user,
  serverInfo: state.infoPage?.serverInfo
});

export default connect(mapStateToProps, { getServerInfo, loadUser })(InfoPage);
