//const API_BASE_URL = "https://app.copiercat.com/api";
//const API_BASE_URL = "http://localhost:5018";
let API_BASE_URL = "";
console.log("REACT_APP_ENV:", process.env.REACT_APP_ENV);
if (process.env.REACT_APP_ENV === 'local') {
  API_BASE_URL = "https://localhost:7059";
} else if (process.env.REACT_APP_ENV === 'production') {
  API_BASE_URL = "https://app.copiercat.com/api"
} else if (process.env.REACT_APP_ENV === 'development') {
  API_BASE_URL = "https://dev.copiercat.com/api"
}


export const API_ENDPOINTS = {
  baseEndpoints: {
    login: `${API_BASE_URL}/login`,
    logout: `${API_BASE_URL}/logout`,
    signup: `${API_BASE_URL}/signup`,
    refresh: `${API_BASE_URL}/refresh`,
  },
  user: {
    signup: `${API_BASE_URL}/user/signup`,
    updateProfile: `${API_BASE_URL}/user/updateProfile`,
    validateToken: `${API_BASE_URL}/validate-token`,
    resendToken: `${API_BASE_URL}/resend-verification`,
    forgotPassword: `${API_BASE_URL}/forgot-password`,
    resetPassword: `${API_BASE_URL}/reset-password`,
    logout: `${API_BASE_URL}/logout`,
    getToken: `${API_BASE_URL}/trader-token`,
    setDarkMode: `${API_BASE_URL}/Account/user/settings`,
  },
  profileEndpoints: { //TODO Rename this profile 
    platforms: `${API_BASE_URL}/Trade/platforms`, //TODO Move this to trade endpoints
    getProfiles: `${API_BASE_URL}/Account/user`,
    getUserPlatforms: `${API_BASE_URL}/Account/platformAccount/info`,
    getPlatforms: `${API_BASE_URL}/Account/platformAccount/info/config`,
    getPlatformInfoByID: `${API_BASE_URL}/Account/platformAccount/info?platformAccountInfoId=`,
    postPlatformData: `${API_BASE_URL}/Account/platformAccount/info`,
    editPlatformData: `${API_BASE_URL}/Account/platformAccount/info`,
    platformAccountInfo: `${API_BASE_URL}/Account/platformAccount/info`,
    deletePlatformDataByID: `${API_BASE_URL}/Account/platformAccount/info?platformAccountId=`,
    setNewContent: `${API_BASE_URL}/Account/user`,
    activeTrade: `${API_BASE_URL}/Trade/link/active`,
    publicAccounts: `${API_BASE_URL}/Account/platformAccount/public-accounts`,
  },
  trade: {
    link: `${API_BASE_URL}/Trade/link`,
    history: `${API_BASE_URL}/trade-history`,
    stats: `${API_BASE_URL}/stats`,
    test: `${API_BASE_URL}/Trade/copy-test`
  },
  health: {
    version: `${API_BASE_URL}/version`,
    downloadTutorial: `${API_BASE_URL}/version-meta-trader`,
    changes: `${API_BASE_URL}/changes`,
  },
  admin: {
    users: `${API_BASE_URL}/Admin/users`,
    roles: `${API_BASE_URL}/Admin/user/roles`,
    lock: `${API_BASE_URL}/Admin/user/lock`,
    updateRole: `${API_BASE_URL}/Admin/user/update-role`,
    tradeLinks: `${API_BASE_URL}/Admin/trade/account-links`,
    activeLinks: `${API_BASE_URL}/Admin/trade/link/toggle-active`,
    getToken: `${API_BASE_URL}/Admin/user/token?userId=`,
    setNewContent: `${API_BASE_URL}/Admin/users/set-new-content`,
    updateDownloadVersions: `${API_BASE_URL}/Admin/update-download-versions`,
    systemTrades: `${API_BASE_URL}/Admin/trade/links`,
    dashboard: `${API_BASE_URL}/Admin/dashboard`,
    revoke_token: `${API_BASE_URL}/Admin/user/revoke-token`,
  },
  affiliate: {
    click: `${API_BASE_URL}/affiliate/track-click`,
  }
};
