import * as React from "react";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useNavigate } from "react-router-dom";
import Header from "Pages/trade/components/Header";
import { useState, useEffect } from "react";
import MDSnackbar from "components/MDSnackbar";
import TradeSettingsIcon from "@mui/icons-material/Tune";
import SaveIcon from "@mui/icons-material/Save";
import swal from "sweetalert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import linkActive from "assets/images/link_active.png";
import linkInActive from "assets/images/link_inactive.png";
import linkDisable from "assets/images/link_disable.png";
import linkError from "assets/images/link_error.png";
import { useSelector, connect, useDispatch } from "react-redux";
import { getBrokerInfo, getPublicAccounts } from "../../api/profile";
import PropTypes from "prop-types";
import TradeLinkSettingsModal from "./components/TradeLinkSettingsModal";
import TradeLinkErrorModal from "./components/TradeLinkErrorModal";
import { API_ENDPOINTS } from "../../apiConfig";
import {Select, InputLabel, MenuItem, Input } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import Slide from '@mui/material/Slide';
import { useMaterialUIController } from "context";
import "./index.css";
import _ from 'lodash';
import {
  getUserPlatforms,
} from "../../api/profile";
import { loadUser } from "api/auth";
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';


import {
  postTradeLink,
  getTradeLink,
  deleteTradeLink,
  activeTradeLink,
  setChange,
  setOpenDialog,
  addTradeLink,
  agreeSave,
  putTradeLink
} from "../../api/trade";
import outlined from "assets/theme/components/button/outlined";
import SymbolMapper from "./components/SymbolMapper";

const useStyles = makeStyles((theme) => ({
  dialogDark: {
    backgroundColor: 'black', 
  },
  dialogWhite: {
    backgroundColor: 'white', 
  },
  select: {
    fontSize: '10px',  // Change the font size here
  },
}));


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Trade = ({
  link,
  error,
  setChange,
  isChange,
  setDlg,
  setOpenDialog,
  getTradeLink,
  plus,
  addTradeLink,
  tradeLink,
  agreeSave,
  agree, 
  user,  
  publicAccounts,
  userPlatforms
}) => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const [active, setActive] = useState(false);

  const [deleteActive, setDeleteActive] = useState(false);

  const [saveActive, setSaveActive] = useState(false);

  const [errorModal, setErrorModal] = React.useState(false);

  const [hovered, setHovered] = useState(false);

  const [ symbols, setSymbols ] = useState([]);

  const navigate = useNavigate();

  const [settingFlag, setSettingFlag] = useState(0);
  const [selectFlag1, setSelectFlag1] = useState(0);
  const [selectFlag2, setSelectFlag2] = useState(0);

  const closeDialog = () => {
    setOpenDialog(false);
    let temp = [];
    setChange(temp);
    setSettingFlag(0);
    setSelectFlag1(0);
    setSelectFlag2(0);
    navigate(localStorage.getItem('url'));
  };

  const onAgree = () => {
    agreeSave(true);
  }
  
  const errorOpen = () => {
    setErrorModal(true);
    if (user.isSubscribed) {
      setTradeInfo({
        ...tradeInfo,
        errorResolved: true
      });
    }
  }

  const addTradeLinks = () => {
    if (tradeLink.length >= 10) {
      setContent("You cannot create more than 10 trade links !");
      setErrorSB(true);
      return;
    }
    let data = [...tradeLink];
    data.push({
      id: "add",
      traderAccountId: '',
      copyCatAccountId: '',
      settings: {
        managmentType: 0,
        fixedTp: "",
        fixedSl: "",
        riskValue: "",
        pairSuffix: ""
      },
      isActive: false,
      errorMessage: null,
      errorResolved: true
    });
    addTradeLink(data);
  }

  const cancelTradeLinks = () => {
    let valueChange = [...isChange];
    if (valueChange.includes(link.id)) {
      let temp = valueChange.filter(item => item !== link.id);
      setChange(temp);
    }
    setSettingFlag(0);
    setSelectFlag1(0);
    setSelectFlag2(0);
    let data = [...tradeLink];
    data = data.filter(item => item.id !== 'add');
    addTradeLink(data);
  }

  const cleanError = () => {
    setTradeInfo({
      ...tradeInfo,
      errorMessage: null
    });
  }

  const onSetting = () => {
    setOpen(true);
  };

  const onActive = async () => {
    if ((isChange.includes(link.id) && saveActive) || (selectFlag1 === 1 || settingFlag === 1 || selectFlag2 === 1)) {
      setContent("You need to save first before you can activate");
      setErrorSB(true);
      return;
    }else{
      var url = API_ENDPOINTS.profileEndpoints.activeTrade + "/?isActive=" + !active + "&tradeAccountLinkId=" + tradeInfo.id;
      try {
        var response = await dispatch(activeTradeLink(url));
        if (response.status === 200) {
          setTradeInfo({
            ...tradeInfo,
            isActive: !active
          });
          if (!active) {
            cleanError();
          }
          setActive(!active);
          setTradeInfo({
            ...tradeInfo,
            isActive: !active
          });
          setContent('Status of link set to ' + !active);
          setSuccessSB(true);
        }else{
          setContent(response.message);
          setErrorSB(true);
        }
      } catch (error) {
        setContent(error.message);
        setErrorSB(true);
      }
    }
  };

  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [content, setContent] = useState("");

  const closeSuccessSB = () => {
    setSuccessSB(false);
    getTradeLink(); 
  };
  const closeInfoSB = () => setInfoSB(false);
  const closeWarningSB = () => setWarningSB(false);
  const closeErrorSB = () => setErrorSB(false);

  const renderSuccessSB = (
    <MDSnackbar
      color='success'
      icon='check'
      title='TradeCopy'
      content={content}
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon='notifications'
      title='TradeCopy'
      content={content}
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color='warning'
      icon='star'
      title='TradeCopy'
      content={content}
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color='error'
      icon='warning'
      title='TradeCopy'
      content={content}
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  if (Object.keys(error).length !== 0) {
    setContent(error.msg);
    setErrorSB(true);
  }

  const validTradeLink = (item) => {
    if (item.traderAccountId === item.copyCatAccountId) {
      return true;
    }
    if ((item.traderAccountId === link.traderAccountInfo?.id) && (item.copyCatAccountId === link.copyCatAccountInfo?.id)) {
      return false;
    }else{
      for (let i = 0; i < tradeLink.length; i++) {
        if ((item.traderAccountId === tradeLink[i].traderAccountInfo?.id) && (item.copyCatAccountId === tradeLink[i].copyCatAccountInfo?.id)) {
          return true;
        }
      }
    }
    return false;
  }

  const onSave = async() => {  
    if (tradeInfo.traderAccountId === tradeInfo.copyCatAccountId) {
      setContent("Please select Trader or CopyCat again");
      setErrorSB(true);
      return;
    }

    let response = "";
    if (tradeInfo.id === 'add') {
      const postData = {
        traderAccountId: tradeInfo.traderAccountId,
        copyCatAccountId: tradeInfo.copyCatAccountId,
        settings: {
          managmentType: tradeInfo.settings.managmentType || 0,
          fixedTp: parseFloat(tradeInfo.settings.fixedTp) || 0, 
          fixedSl: parseFloat(tradeInfo.settings.fixedSl) || 0,
          riskValue: parseFloat(tradeInfo.settings.riskValue) || 0,
          pairSuffix: tradeInfo.settings.pairSuffix || "",
          symbolMap: tradeInfo.settings.symbolMap
        },
        isActive: false, 
        resolvedError: false,
      };

      response = await dispatch(postTradeLink(postData));
    }
    else{
      const postData = {
        id: tradeInfo.id,
        settings: {
          managmentType: tradeInfo.settings.managmentType || 0,
          fixedTp: parseFloat(tradeInfo.settings.fixedTp) || 0, 
          fixedSl: parseFloat(tradeInfo.settings.fixedSl) || 0,
          riskValue: parseFloat(tradeInfo.settings.riskValue) || 0,
          pairSuffix: tradeInfo.settings.pairSuffix || "",
          symbolMap: tradeInfo.settings.symbolMap
        },
        isActive: false, 
        resolvedError: false,
      };

      response = await dispatch(putTradeLink(postData));
    }

    if (response.status === 200) {
      let valueChange = [...isChange];
      if (valueChange.includes(link.id)) {
        let temp = valueChange.filter(item => item !== link.id);
        setChange(temp);
      }
      setSettingFlag(0);
      setSelectFlag1(0);
      setSelectFlag2(0);
      if (agree) {
        setOpenDialog(false);
        if (isChange.length === 1) {
          agreeSave(false);
          navigate(localStorage.getItem('url'));
        }
      }
      setContent("Trade link has been saved");
      setSuccessSB(true);
    }else{
      setContent(response.message);
      setErrorSB(true);
    }
  };

  useEffect(()=>{
    if (agree && isChange.length > 0 && isChange.includes(link.id)) {
      onSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agree, isChange, link]);

  const onDelete = () => {
    swal({
      title: "Are you sure?",
      text: "You will no longer be connected to this platform!",
      icon: "warning",
      buttons: { cancel: "No", confirm: "OK" },
      dangerMode: true,
    }).then(async(willDelete) => {
      if (willDelete) {
        if (tradeInfo.isActive) {
          setContent("Cannot activate links with deleted accounts !");
          setErrorSB(true);
          return;
        }
        let response = await dispatch(deleteTradeLink(tradeInfo.id));

        if (response.status === 200) {
          setDeleteActive(false);
          setActive(false);
          swal("Your Tradelink has been deleted!", {
            icon: "success",
          });
        }else{
          setContent(response.message);
          setErrorSB(true);
        }     
      } 
    });
  };


  const tradeAccounts = useSelector((state) => state.profile.userPlatforms || []);

  const [tradeInfo, setTradeInfo] = useState({
    id:'',
    traderAccountId: '',
    copyCatAccountId: '',
    settings: {
      managmentType: 0,
      riskValue: 0,
      fixedSl: 0,
      fixedTp: 0,
      pairSuffix: "",
      symbolMap: {}
    },
    isActive: false,
    errorMessage: null,
    errorResolved: false
  });

  useEffect(() => {
    if (link?.id !== 'add') {
      setDeleteActive(true);
      setSaveActive(true);
    }
    setTradeInfo(prev => ({
      id:link?.id || 'add',
      traderAccountId: link?.traderAccountInfo?.id || '',
      copyCatAccountId: link?.copyCatAccountInfo?.id || '',
      settings: {
        managmentType: link?.settings?.managmentType || prev.settings.managmentType,
        fixedTp: link?.settings?.fixedTp || prev.settings.fixedTp,
        fixedSl: link?.settings?.fixedSl || prev.settings.fixedSl,
        riskValue: link?.settings?.riskValue || prev.settings.riskValue,
        pairSuffix: link?.settings?.pairSuffix || prev.settings.pairSuffix,
        symbolMap: link?.settings?.symbolMap || prev.settings.symbolMap,
      },
      isActive: link?.isActive,
      errorMessage: link?.errorMessage,
      errorResolved: link?.errorResolved,
    }));
    let jsonObject = Object.entries(link?.settings?.symbolMap || {});
    let temp = [];
    jsonObject.map(([key, value], index)=> (
      temp.push({ addProp: key, addValue: value })
    ));
    setSymbols(temp);
    setActive(link?.isActive);
  }, [link]);

  const handleSelectionChange = (key, value) => {
    if (key === 'traderAccountId') {
      if (value === "public") {
        return;
      }
      let valid = validTradeLink({traderAccountId: value, copyCatAccountId: tradeInfo.copyCatAccountId});
      if (valid === true) {
        let valueChange = [...isChange];
        if (valueChange.includes(link.id)) {
          let temp = valueChange.filter(item => item !== link.id);
          setChange(temp);
        }
        setTradeInfo({
          ...tradeInfo,
          traderAccountId: '',
        });
        setSaveActive(false);
        setContent("Cannot create a duplicate link !");
        setErrorSB(true);
        return;
      }
      if (value !== link.traderAccountInfo?.id) {
        let valueChange = [...isChange];
        if (!valueChange.includes(link.id)) {
          valueChange.push(link.id);
        }
        setChange(valueChange);
        setSelectFlag1(1);
      }
      if (value === link.traderAccountInfo?.id) {
        let valueChange = [...isChange];
        if (valueChange.includes(link.id)) {
          let temp = valueChange.filter(item => item !== link.id);
          setChange(temp);
        }
        setSelectFlag1(0);
      }
      if (tradeInfo.copyCatAccountId === value || tradeInfo.copyCatAccountId === '') {
        setSaveActive(false);
      }else{
        setSaveActive(true);
      }
    }else{
      let valid = validTradeLink({traderAccountId: tradeInfo.traderAccountId, copyCatAccountId: value});
      if (valid === true) {
        let valueChange = [...isChange];
        if (valueChange.includes(link.id)) {
          let temp = valueChange.filter(item => item !== link.id);
          setChange(temp);
        }
        setTradeInfo({
          ...tradeInfo,
          copyCatAccountId: '',
        });
        setSaveActive(false);
        setContent("Cannot create a duplicate link !");
        setErrorSB(true);
        return;
      }
      if ((value !== link.copyCatAccountInfo?.id)) {
        let valueChange = [...isChange];
        if (!valueChange.includes(link.id)) {
          valueChange.push(link.id);
        }
        setChange(valueChange);
        setSelectFlag2(1);
      }
      if (value === link.copyCatAccountInfo?.id) {
        let valueChange = [...isChange];
        if (valueChange.includes(link.id)) {
          let temp = valueChange.filter(item => item !== link.id);
          setChange(temp);
        }
        setSelectFlag2(0);
      }
      if (tradeInfo.traderAccountId === value || tradeInfo.traderAccountId === '') {
        setSaveActive(false);
      }else{
        setSaveActive(true);
      }
    }
    setTradeInfo({ ...tradeInfo, [key]: value });
  };

  const saveSymbol = (value, flag) => {
    setSymbols(value);
    let temp={};
    for (let i = 0; i < value.length; i++) {
      temp[value[i].addProp] = value[i].addValue;
    }
    if (flag === 1) {
      handleSettingsChange("symbolMap", temp);
    }
  }

  const handleSettingsChange = (settingKey, value) => {
    let tempTradeInfo = tradeInfo.settings;
    if (settingKey === 'pairSuffix' || settingKey === "symbolMap") {
      tempTradeInfo[settingKey] = value;
    }else{
      tempTradeInfo[settingKey] = Number(value);
    }
    if (settingKey === 'managmentType') {
      if (value === 0) {
        setTradeInfo(prev => ({
          ...prev,
          settings: { ...prev.settings, riskValue: 0 },
        }));
        tempTradeInfo.riskValue = 0;
      }
    }
    if (settingKey === "symbolMap") {
      setTradeInfo(prev => ({
        ...prev,
        settings: { ...prev.settings, symbolMap: value },
      }));
    }
    if (!_.isEqual(tempTradeInfo, link.settings)) {
      let valueChange = [...isChange];
      if (!valueChange.includes(link.id)) {
        valueChange.push(link.id);
      }
      setChange(valueChange);
      setSettingFlag(1);
    }
    if (_.isEqual(tempTradeInfo, link.settings)) {
      let valueChange = [...isChange];
      if (valueChange.includes(link.id)) {
        let temp = valueChange.filter(item => item !== link.id);
        setChange(temp);
      }
      setSettingFlag(0);
    }
    if (settingKey === 'pairSuffix' || settingKey === 'symbolMap') {
      setTradeInfo(prev => ({
        ...prev,
        settings: { ...prev.settings, [settingKey]: value },
      }));
    }else{
      setTradeInfo(prev => ({
        ...prev,
        settings: { ...prev.settings, [settingKey]: Number(value) },
      }));
    }
  };
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  let selectStyle = null;
  let settingColor = null;
  if (darkMode) {
    settingColor = '#ffffff';
    selectStyle = {
      fontSize: '0.775rem', // Default font size
      [theme.breakpoints.up('sm')]: {
        fontSize: '0.875rem', // Font size for small screens and up
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '0.875rem', // Font size for medium screens and up
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '0.875rem', // Font size for large screens and up
      },
      color: '#ffffff'
    };
  }

  const color = React.useMemo(() => {
    if (darkMode && ((isChange.includes(link.id) && saveActive) || (selectFlag1 === 1 || settingFlag === 1 || selectFlag2 === 1))) {
      return '#ffffff'; 
    }else if (darkMode) {
      return '#344767';
    }else if(!darkMode && !((isChange.includes(link.id) && saveActive) || (selectFlag1 === 1 || settingFlag === 1 || selectFlag2 === 1))) {
      return '#939fb2';
    }else if(!darkMode && ((isChange.includes(link.id) && saveActive) || (selectFlag1 === 1 || settingFlag === 1 || selectFlag2 === 1))) {
      if (!saveActive) {
        return '#939fb2';
      }else{
        return null;
      }
    }
  }, [darkMode, saveActive, isChange, link.id, selectFlag1, selectFlag2, settingFlag]);

  const deleteColor = React.useMemo(()=> {
    if (darkMode && deleteActive) {
      return '#ffffff'; 
    }
    if (darkMode && !deleteActive) {
      return '#344767';
    }else if(!darkMode && !deleteActive) {
      return '#939fb2';
    }else if (!darkMode && deleteActive) {
      return null;
    }
  }, [darkMode, deleteActive])

  const closeErrorModal = () => {
    setErrorModal(false);
    if ((!link?.errorResolved && link?.errorMessage !== null) && user.isSubscribed) {
      onSave();
    }
  }

  const addColor = React.useMemo(()=> {
    if (darkMode) {
      return '#ffffff';
    }else{
      return '#344767';
    }
  }, [darkMode])

  const classes = useStyles();

  const allowedMasterPlatformIds = ["65f1b1b07334a3f59d89357f", "662ff9328314c7ca05618833", "664f536a935cfc84eeabd7b9", "6745cc06474b8d78788e38a7"];

  return (
    <div>
      {renderSuccessSB}
      {renderErrorSB}
      {renderWarningSB}
      {renderInfoSB}
      <Dialog
        open={setDlg && isChange.includes(link.id)}
        TransitionComponent={Transition}
        keepMounted
        onClose={closeDialog}
        classes={{ paper: darkMode? classes.dialogDark : classes.dialogWhite }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Would you like to save the changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You need to save first before you can activate the trade link.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} style={outlined}>Discard</Button>
          <Button onClick={onAgree} style={outlined}>Save</Button>
        </DialogActions>
      </Dialog>
      <TradeLinkSettingsModal symbols={symbols} saveSymbol={saveSymbol} open={open} setOpen={setOpen} handleSettingsChange={handleSettingsChange} tradeInfo={tradeInfo} />
      <TradeLinkErrorModal open={errorModal} setOpen={closeErrorModal} errorMessage={link.errorMessage} createdDate={link.createdDate? link.createdDate : "2024-05-15"} updatedDate={link.updatedDate? link.updatedDate : "2024-05-15"} tradeLinkId={link.id} />
      <Grid container spacing={1} className='p-0 justify-center sm:p-4'>
        <Grid item xs={3} md={4} xl={4} sx={{ display: "flex" }}>
          <FormControl variant='standard' className='w-full'>
          <InputLabel
            sx={{
              fontSize: '0.775rem', // Default font size
              [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem', // Font size for small screens and up
              },
              [theme.breakpoints.up('md')]: {
                fontSize: '0.875rem', // Font size for medium screens and up
              },
              [theme.breakpoints.up('lg')]: {
                fontSize: '0.875rem', // Font size for large screens and up
              },
            }}
          >
            Trader
          </InputLabel>

            {
            (tradeAccounts.findIndex(p => p.id === tradeInfo?.traderAccountId) === -1 && publicAccounts.findIndex(p => p.id === tradeInfo?.traderAccountId) === -1)  && tradeInfo?.id !== "add"?
              <Input type="text" value={"The account is deleted"} readOnly className="pl-3" />
              :
              <Select
                value={tradeInfo?.traderAccountId}
                onChange={(e) => handleSelectionChange('traderAccountId', e.target.value)}
                displayempty={true.toString()}
                readOnly={tradeInfo.id !== "add"}
                id="traderSelect"
                sx={selectStyle}
              >
                <MenuItem key={""} value={"public"}>
                  <p className="font-bold underline">Private Accounts</p>
                </MenuItem>
                {
                  tradeAccounts?.map((account) => (
                    allowedMasterPlatformIds.includes(account.platformId) && account.accountName !== "Subscribers" && (
                      <MenuItem key={account.id} value={account.id} disabled={!user?.isSubscribed}>
                        {account.accountName}
                      </MenuItem>
                    )
                  ))
                }
                <MenuItem key={""} value={"public"}>
                  <p className="font-bold underline">Public Accounts</p>
                </MenuItem>
                {
                  publicAccounts?.map((account) => (
                    userPlatforms?.findIndex(p=> p.id === account.id) === -1 && 
                    <MenuItem key={account.id} value={account.id} disabled={!user?.isSubscribed}>
                      {account.accountName}
                    </MenuItem>
                  ))
                }
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
          <div className='flex w-full h-full'>
            {
              user?.isSubscribed?
              (tradeInfo?.errorResolved || tradeInfo.errorMessage === null) ? (
                active ? 
                (!deleteActive ? 
                  (<img src={linkDisable} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' />) :
                  (<img src={linkActive} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' onClick={onActive} id="reActiveBtn" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }} />)
                ) 
                : 
                (!deleteActive ? 
                  (<img src={linkDisable} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' />) :
                  (<img src={linkInActive} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' onClick={onActive} id="activeBtn" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }} />)
                ) 
              ):
              (
                <img src={linkError} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} style={{ transition: 'transform 0.3s ease-in-out', transform: hovered ? 'scale(1.2)' : 'scale(1)' }} />
              )
              :
              (<img src={linkDisable} alt='Share' className='w-4 mt-3 sm:m-auto sm:w-6' />)
            }
          </div>
        </Grid>
        <Grid item xs={3} md={4} xl={4} sx={{ display: "flex" }}>
          <FormControl variant='standard' className='w-full'>
          <InputLabel
            sx={{
              fontSize: '0.775rem', // Default font size
              [theme.breakpoints.up('sm')]: {
                fontSize: '0.875rem', // Font size for small screens and up
              },
              [theme.breakpoints.up('md')]: {
                fontSize: '0.875rem', // Font size for medium screens and up
              },
              [theme.breakpoints.up('lg')]: {
                fontSize: '0.875rem', // Font size for large screens and up
              },
            }}
          >
            Copy Cat
          </InputLabel>
            {
              tradeAccounts.findIndex(p => p.id === tradeInfo?.copyCatAccountId) === -1  && tradeInfo?.id !== "add"?
              <Input type="text" value={"The account is deleted"} readOnly className="pl-3" />
              :
              <Select
                  value={tradeInfo?.copyCatAccountId}
                  onChange={(e) => handleSelectionChange('copyCatAccountId', e.target.value)}
                  displayempty={true.toString()}
                  readOnly={tradeInfo.id !== "add"}
                  id="copyTraderSelect"
                  sx={selectStyle}
                  >
                {tradeAccounts.map((account) => (
                  !account?.isPublic &&
                  <MenuItem key={account?.id} value={account?.id} disabled={!user?.isSubscribed}>
                  {account?.accountName}
                  </MenuItem>
                ))}
              </Select>
            }
          </FormControl>
        </Grid>
        <Grid item xs={4} md={2} xl={2} sx={{ display: "flex" }}>
          <div className='m-auto w-1/5 sm:w-1/6' onClick={onSetting} >
            <TradeSettingsIcon
              sx={{
                width: '30px',
                height: '30px',
                color: settingColor,
                  "&:hover": {
                    width: '35px',
                    height: '35px'
                  },
                  '@media (min-width:200px)': {
                    width: '17px',
                    height: '17px',
                    '&:hover': {
                      width: '20px',
                      height: '20px',
                    },
                  },
                  '@media (min-width:500px)': {
                    width: '25px',
                    height: '25px',
                    '&:hover': {
                      width: '30px',
                      height: '30px',
                    },
                  },
                  '@media (min-width:800px)': {
                    width: '30px',
                    height: '30px',
                    '&:hover': {
                      width: '35px',
                      height: '35px',
                    },
                  },
              }} />
          </div>
          {((isChange.includes(link.id) && saveActive) || (selectFlag1 === 1 || settingFlag === 1 || selectFlag2 === 1)) ?  
                (saveActive?
                  (darkMode?
                    <div className='m-auto w-1/5 sm:w-1/6 icon pulsing2 relative' onClick={onSave} id="saveBtn">
                      <SaveIcon
                        sx={{
                            width: '30px',
                            height: '30px',
                            color: color,
                            "&:hover": {
                              width: '35px',
                              height: '35px'
                            },
                            '@media (min-width:200px)': {
                              width: '17px',
                              height: '17px',
                              '&:hover': {
                                width: '20px',
                                height: '20px',
                              },
                            },
                            '@media (min-width:500px)': {
                              width: '25px',
                              height: '25px',
                              '&:hover': {
                                width: '30px',
                                height: '30px',
                              },
                            },
                            '@media (min-width:800px)': {
                              width: '30px',
                              height: '30px',
                              '&:hover': {
                                width: '35px',
                                height: '35px',
                              },
                            },
                        }} />
                    </div>
                    :
                    <div className='m-auto w-1/5 sm:w-1/6 icon pulsing relative' onClick={onSave} id="saveBtn">
                      <SaveIcon
                        sx={{
                            width: '30px',
                            height: '30px',
                            color: color,
                            "&:hover": {
                              width: '35px',
                              height: '35px'
                            },
                            '@media (min-width:200px)': {
                              width: '17px',
                              height: '17px',
                              '&:hover': {
                                width: '20px',
                                height: '20px',
                              },
                            },
                            '@media (min-width:500px)': {
                              width: '25px',
                              height: '25px',
                              '&:hover': {
                                width: '30px',
                                height: '30px',
                              },
                            },
                            '@media (min-width:800px)': {
                              width: '30px',
                              height: '30px',
                              '&:hover': {
                                width: '35px',
                                height: '35px',
                              },
                            },
                        }} />
                    </div>
              )
                  :
                  <div className='m-auto w-1/5 sm:w-1/6'>
                    <SaveIcon disabled 
                      sx={{
                        width: '30px',
                        height: '30px',
                        color: color,
                        '&:hover': {
                          width: '35px',
                          height: '35px',
                        },
                        '@media (min-width:200px)': {
                          width: '17px',
                          height: '17px',
                          '&:hover': {
                            width: '20px',
                            height: '20px',
                          },
                        },
                        '@media (min-width:500px)': {
                          width: '25px',
                          height: '25px',
                          '&:hover': {
                            width: '30px',
                            height: '30px',
                          },
                        },
                        '@media (min-width:800px)': {
                          width: '30px',
                          height: '30px',
                          '&:hover': {
                            width: '35px',
                            height: '35px',
                          },
                        },
                      }}/>
                  </div>
                )
                :
                <div className='m-auto w-1/5 sm:w-1/6'>
                  <SaveIcon disabled 
                    sx={{
                      width: '30px',
                      height: '30px',
                      '&:hover': {
                        width: '35px',
                        height: '35px',
                      },
                      color: color,
                      '@media (min-width:200px)': {
                        width: '17px',
                        height: '17px',
                        '&:hover': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                      '@media (min-width:500px)': {
                        width: '25px',
                        height: '25px',
                        '&:hover': {
                          width: '30px',
                          height: '30px',
                        },
                      },
                      '@media (min-width:800px)': {
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                          width: '35px',
                          height: '35px',
                        },
                      },
                    }}/>
                </div>
          }
          {!deleteActive ? 
            (
              <div className='m-auto w-1/5 sm:w-1/6'>
                <DeleteOutlineIcon 
                  disabled
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: deleteColor,
                    '@media (min-width:200px)': {
                      width: '17px',
                      height: '17px',
                    },
                    '@media (min-width:500px)': {
                      width: '25px',
                      height: '25px',
                    },
                    '@media (min-width:800px)': {
                      width: '30px',
                      height: '30px',
                    },
                  }}
                />
              </div>
            ) :
            (
              <div className='m-auto w-1/5 sm:w-1/6' id="deleteBtn" onClick={onDelete}>
                <DeleteOutlineIcon
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: deleteColor,
                      "&:hover": {
                        width: '35px',
                        height: '35px'
                      },
                      '@media (min-width:200px)': {
                        width: '17px',
                        height: '17px',
                        '&:hover': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                      '@media (min-width:500px)': {
                        width: '25px',
                        height: '25px',
                        '&:hover': {
                          width: '30px',
                          height: '30px',
                        },
                      },
                      '@media (min-width:800px)': {
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                          width: '35px',
                          height: '35px',
                        },
                      },
                  }}/>
              </div>
            )
          }
          {(tradeInfo?.errorResolved || tradeInfo.errorMessage === null) ? 
            (
              <div className='m-auto w-1/5 sm:w-1/6' onClick={errorOpen}>
                <SearchIcon 
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: addColor,
                    "&:hover": {
                      width: '35px',
                      height: '35px'
                    },
                    '@media (min-width:200px)': {
                      width: '17px',
                      height: '17px',
                      '&:hover': {
                        width: '20px',
                        height: '20px',
                      },
                    },
                    '@media (min-width:500px)': {
                      width: '25px',
                      height: '25px',
                      '&:hover': {
                        width: '30px',
                        height: '30px',
                      },
                    },
                    '@media (min-width:800px)': {
                      width: '30px',
                      height: '30px',
                      '&:hover': {
                        width: '35px',
                        height: '35px',
                      },
                    },
                  }}/>
              </div>
            ) :
            (
              <div className='m-auto w-1/5 sm:w-1/6 icon pulsing1 relative' onClick={errorOpen}>
                <SearchIcon
                  sx={{
                    width: '30px',
                    height: '30px',
                    color: 'red',
                      "&:hover": {
                        width: '35px',
                        height: '35px'
                      },
                      '@media (min-width:200px)': {
                        width: '17px',
                        height: '17px',
                        '&:hover': {
                          width: '20px',
                          height: '20px',
                        },
                      },
                      '@media (min-width:500px)': {
                        width: '25px',
                        height: '25px',
                        '&:hover': {
                          width: '30px',
                          height: '30px',
                        },
                      },
                      '@media (min-width:800px)': {
                        width: '30px',
                        height: '30px',
                        '&:hover': {
                          width: '35px',
                          height: '35px',
                        },
                      },
                  }} />
              </div>
            )
          }
        </Grid>
        <Grid item xs={1} md={1} xl={1} sx={{ display: "flex" }}>
          {
          plus && 
           (link.id === 'add'? 
            <div className='m-auto w-full' onClick={cancelTradeLinks}>
              <HorizontalRuleIcon 
                sx={{
                  '@media (min-width:200px)': {
                    width: '19px',
                    height: '19px',
                    color: addColor,
                    '&:hover': {
                      width: '22px',
                      height: '22px',
                    },
                  },
                  '@media (min-width:500px)': {
                    width: '28px',
                    height: '28px',
                    '&:hover': {
                      width: '30px',
                      height: '30px',
                    },
                  },
                  '@media (min-width:800px)': {
                    width: '33px',
                    height: '33px',
                    '&:hover': {
                      width: '36px',
                      height: '36px',
                    },
                  },
                }}/>
              </div>
              :
              (user?.isSubscribed?
                <div className='m-auto w-full' onClick={addTradeLinks} id="addTradeLinkBtn">
                  <AddIcon 
                    sx={{
                      '@media (min-width:200px)': {
                        width: '19px',
                        height: '19px',
                        color: addColor,
                        '&:hover': {
                          width: '22px',
                          height: '22px',
                        },
                      },
                      '@media (min-width:500px)': {
                        width: '28px',
                        height: '28px',
                        '&:hover': {
                          width: '30px',
                          height: '30px',
                        },
                      },
                      '@media (min-width:800px)': {
                        width: '33px',
                        height: '33px',
                        '&:hover': {
                          width: '36px',
                          height: '36px',
                        },
                      },
                    }}/>
                </div>
                :
                <div className='m-auto w-full' >
                  <AddIcon 
                    disabled
                    sx={{
                      color: '#939FB2',
                      '@media (min-width:200px)': {
                        width: '19px',
                        height: '19px',
                      },
                      '@media (min-width:500px)': {
                        width: '28px',
                        height: '28px',
                      },
                      '@media (min-width:800px)': {
                        width: '33px',
                        height: '33px',
                      },
                    }}/>
                </div>
              )
              )
            }
        </Grid>
      </Grid>
    </div>
  );
};

const TradeList = ({ links }) => {
  let tradeLinks = links || [];
  if (tradeLinks.length === 0) {
    tradeLinks.length = 0;
    tradeLinks.push({
      id: "add",
      traderAccountId: '',
      copyCatAccountId: '',
      settings: {
        managmentType: 0,
        fixedTp: "",
        fixedSl: "",
        riskValue: "",
        pairSuffix: ""
      },
      isActive: false,
      errorMessage: null
    });
  }

  return (
    <MDBox>
      {[...tradeLinks].reverse().map((link, index) => (
        index === 0?
          <TradeLink link = {link} key={link.id} plus={true} />
          :
          <TradeLink link = {link} key={link.id} plus={false} />
        
      ))}
    </MDBox>
  );
};

function TradeCopy({tradeLink}) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTradeLink());
    dispatch(loadUser());
    dispatch(getPublicAccounts());
    dispatch(getUserPlatforms());
  }, [dispatch]);
  
	const [controller] = useMaterialUIController();
	const {
		darkMode,
	} = controller;

  return (
    <DashboardLayout style={{ Padding: 0 }}>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <div className="grid grid-cols-2 md:grid-cols-3 content-end mt-3 mb-2">
          <div className="col-start-2 md:col-start-3 grid grid-cols-3">
            <div className="grid grid-flow-col grid-cols-3 justify-items-start md:grid-rows-2 sm:grid-rows-2 md:justify-items-center sm:justify-items-center">
              <img src={linkActive} alt='Share' className='w-4 m-auto md:w-6 sm:w-5 md:col-span-2 sm:col-span-2' />
              {darkMode?
                <p className="text-sm content-center col-span-2 text-gray-50 sm:text-xs">Active</p>
                :
                <p className="text-sm content-center col-span-2 sm:text-xs">Active</p>
              }
            </div>
            <div className="grid grid-flow-col grid-cols-3 justify-items-start md:grid-rows-2 sm:grid-rows-2 md:justify-items-center sm:justify-items-center">
              <img src={linkDisable} alt='Share' className='w-4 m-auto md:w-6 sm:w-5 md:col-span-2 sm:col-span-2' />
              {darkMode?
                <p className="text-sm content-center col-span-2 text-gray-50 sm:text-xs">Disabled</p>
                :
                <p className="text-sm content-center col-span-2 sm:text-xs">Disabled</p>
              }
            </div>
            <div className="ml-[16px] sm:ml-[0px] grid grid-flow-col grid-cols-3 justify-items-start md:grid-rows-2 sm:grid-rows-2 md:justify-items-center sm:justify-items-center">
              <img src={linkError} alt='Share' className='w-4 m-auto md:w-6 sm:w-5 md:col-span-2 sm:col-span-2' />
              {darkMode?
                <p className="text-sm content-center col-span-2 text-gray-50 sm:text-xs">Error</p>
                :
                <p className="text-sm content-center col-span-2 sm:text-xs">Error</p>
              }
            </div>
          </div>
        </div>
        <MDBox mb={3}>
          <TradeList links={tradeLink} key={1}/>
        </MDBox>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

TradeCopy.propTypes = {
  getBrokerInfo: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  error: state.tradeLink?.error,
  isChange: state.tradeLink?.isChange,
  setDlg: state.tradeLink?.setDlg,
  tradeLink: state.tradeLink?.tradeLink || [],
  addFlag: state.tradeLink?.addFlag,
  agree: state.tradeLink?.agree,
  user: state.auth?.user,
  publicAccounts: state.profile?.publicAccounts,
  userPlatforms: state.profile?.userPlatforms,
});

export const TradeLink = connect(mapStateToProps, { getBrokerInfo, setChange, setOpenDialog, getTradeLink, addTradeLink, agreeSave })(Trade);
export default connect(mapStateToProps, { getBrokerInfo,addTradeLink, loadUser, getPublicAccounts })(TradeCopy);